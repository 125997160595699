import Chip from "@mui/material/Chip";
import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { getChatTimestamp } from "../../../../../../bae-client/Utils";
import http from "../../../../../../bae-client/HTTP";
import DocumentViewerModal from "../../../../../stylized/DocumentViewerModal";
import DocViwer from "../../../../../stylized/DocViwer";
import { Menu, MenuItem } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import "./stepStyle.css";
import { setUpdatedInstanceBranch } from "../../../../../../actions/Rippleaction";
import { fetchDocumentChainList } from "../../../../../../actions/DocumentChainActions";
import AddStep from "../modals/AddStep";
import DialogView from "../../../../../stylized/DialogView";
import AlertDialog from "../../../../../stylized/AlertDialog";
import { showAlert } from "../../../../../../actions/AlertView";
import AddStepStepper from "../modals/AddStepStepper";
import Loader from "../../../../../stylized/Loader";
import { getInstanceSteps } from "../../../../../functional/UnityCentral/ContentPane/document-chain/helper";
import { validateFileType } from "../../Workspace/constant";
import * as Mixpanel from "../../../../../../mix-panel/Mixpanel";
import FormWrapper from "../../Integration/FormWrapper/FormWrapper";
import CustomSnackbar from "../../../../../stylized/CustomSnackbar/CustomSnackbar";
import { setSnackbarAction } from "../../../../../../services/services/app";
import AddBranch from "../modals/AddBranch";

const Step = ({ instanceDetails, genericId, data, expectedDateMap }) => {
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";
    const instanceSteps = data?.instanceSteps;
    const instanceBranch = useSelector((state) => state.instanceBranch);
    const [selectedMergeBlocks, setSelectedMergeBlocks] = React.useState([]);
    const [branchViewData, setBranchViewData] = React.useState(null);
    const [selectedGenericSteps, setSelectedGenericSteps] = React.useState([]);
    const [displayShow, setDisplayShow] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [rawDataToEdit, setRawDataToEdit] = React.useState(null);
    const [currentShowFileName, setCurrentShowFileName] = React.useState("");
    const [currentShowFileLocation, setCurrentShowFileLocation] =
        React.useState("");
    const [branchShow, setBranchShow] = React.useState(false);
    const [selectedInstanceSteps, setSelectedInstanceSteps] = React.useState(
        []
    );
    const [showLoader, setShowLoader] = React.useState(false);
    const [showEditStepModal, setShowEditStepModal] = React.useState(false);
    const [showAlertDialog, setShowAlertDialog] = React.useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = React.useState("");
    const [alertDialogShow, setAlertDialogShow] = React.useState(false);
    const [alertDialogContent, setAlertDialogContent] = React.useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const [documentModalShow, setDocumentModalShow] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [showStepperForm, setShowStepperForm] = React.useState(false);
    const [anchorUploadEl, setanchorUploadEl] = React.useState(false);
    const [selectedInstance, setSelectedInstance] = React.useState(null);
    const tableFileUplode = React.useRef(null);

    const dispatch = useDispatch();

    const handleClick = (event, row) => {
        // if (isReadOnlyUser) return;
        setAnchorEl(event.currentTarget);
        setanchorUploadEl(null);
    };
    const addBranch = (
        branchId,
        branchName,
        instanceId,
        instanceStepId,
        setBranchShow,
        checkedOne
    ) => {
        setShowLoader(true);
        setBranchShow(false);
        http.sendRestRequest(
            `documentChain/instance/step/split?genericId=${
                instanceDetails.genericId
            }&branchId=${branchId}&branchName=${encodeURIComponent(
                branchName
            )}&instanceStepId=${instanceStepId}&createPromiseBlocks=${checkedOne}`,
            "PUT"
        )
            .then(() => {
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: `Branch Added successfully!`,
                        severity: "success",
                    })
                );
                // setShowSnackbar(true);
                // handleInstanceClick(selectedInstance);
                setShowLoader(false);
                document.getElementById("div_instanceRefresh_icon").click();
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };
    const showAlertView = (title, body, messageId, buttonId, isError) => {
        setAlertDialogContent({
            title: title,
            message: body,
            messageId: messageId,
            buttonId: buttonId,
        });
        isError && setAlertDialogShow(true);
    };
    const handleConfirmationDialog = (response, isDelete) => {
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });
        if (response === "YES") {
            if (isDelete === "DELETE") {
                setShowLoader(true);
                http.sendRestRequest(
                    `documentChain/instance/branch?genericId=${instanceDetails.genericId}&instanceStepId=${instanceDetails.instanceStepId}`,
                    "DELETE"
                )
                    .then((resp) => {
                        setShowLoader(false);
                        if (resp === 200) {
                            document
                                .getElementById("div_instanceRefresh_icon")
                                .click();
                            dispatch(
                                setSnackbarAction({
                                    open: true,
                                    message: `Branch Deleted successfully!`,
                                    severity: "success",
                                })
                            );
                            // setSnackbarMessage("Branch Deleted successfully!");
                            // handleInstanceClick(selectedInstance); //fetchinstance call
                            // setShowSnackbar(true);
                        } else {
                            showAlertView(
                                "Delete Item",
                                "Something went wrong, Please try again!"
                            );
                        }
                    })
                    .catch((err) => {
                        showAlertView(
                            "Operation Failed",
                            err.error,
                            "",
                            "",
                            true
                        );
                        setShowLoader(false);
                    });
            }
        }
    };
    const deleteClickHandler = (e) => {
        setAnchorEl(null);
        setAlertDialogContent({
            title: "Delete Branch",
            message: `Are you sure you want to delete rest of the steps from ${instanceDetails.branchName} Branch?`,
            isAlert: true,
            buttonName: "DELETE",
            buttonId: "btn_delete",
        });
        setAlertDialogShow(true);
    };
    const handleCloseUploadDialog = () => {
        // setShowUploadOption(false);
        setShowEditStepModal(false);
        setShowStepperForm(false);
    };
    const handleCloseStepperForm = () => {
        setShowStepperForm(false);
    };
    const handleFormShow = () => {
        setSelectedInstance(instanceDetails);
        // console.log(instanceDetails);
        setShowStepperForm(true);
        setAnchorEl(null);
    };

    /**
     * @param {Object} prevStep - the previous step object
     * @param {number} duration - the duration in minutes
     * @return {number} the calculated actual date in milliseconds
     */
    const getActualDate = (prevStep, duration) => {
        if (!prevStep) return "";
        if (prevStep.actualDate) {
            return new Date(prevStep.actualDate).getTime() + duration * 60000;
        }
        if (prevStep.expectedDate) {
            return new Date(prevStep.expectedDate).getTime() + duration * 60000;
        } else
            return getActualDate(
                instanceSteps.find(
                    (step) =>
                        step.instanceStepId === prevStep?.expectedDurationFrom
                ),
                prevStep.expectedDuration + duration
            );
    };
    /**
     * Returns the expected date for a given instance step.
     *
     * @param {object} instanceStep - The instance step for which to calculate the expected date
     * @return {Date} The expected date for the instance step in UTC format which needs to be converted to local date
     */
    const getExpectedDateForStep = (instanceStep) => {
        if (!instanceStep) return "";
        if (instanceStep.expectedDate) return instanceStep.expectedDate;
        const prevStep = instanceSteps.find(
            (step) => step.instanceStepId === instanceStep?.expectedDurationFrom
        );
        if (!prevStep) return "";
        const dateInTime = getActualDate(
            prevStep,
            instanceStep.expectedDuration
        );
        return new Date(dateInTime);
    };
    const renderExpectedTimeRow = () => {
        const isDocumentAvailable =
            instanceDetails.instanceBlockDocumentName !== "" ? true : false;
        const dateToShow = isDocumentAvailable
            ? instanceDetails.actualDate
            : instanceDetails.expectedDate;
        let expectedDate = expectedDateMap?.get(
            instanceDetails?.instanceStepId
        )?.expectedDate;
        return (
            <div className="content textEllipsis">
                {isDocumentAvailable && "Uploaded On: "}
                {isDocumentAvailable &&
                    getChatTimestamp(dateToShow, "mm/dd/yyyy hh:mm aa")}
                <div>
                    {!isDocumentAvailable && "Expected On: "}
                    {!isDocumentAvailable
                        ? getChatTimestamp(expectedDate, "mm/dd/yyyy hh:mm aa")
                        : null}
                </div>
            </div>
        );
    };
    // const renderExpectedTimeRow = () => {
    //     const isInstanceStarted = instanceSteps?.filter(
    //         (step) => step.actualDate
    //     ).length
    //         ? true
    //         : false;
    //     const isDocumentAvailable =
    //         instanceDetails.instanceBlockDocumentName !== "" ? true : false;
    //     const dateToShow = isDocumentAvailable
    //         ? instanceDetails.actualDate
    //         : isInstanceStarted
    //         ? getExpectedDateForStep(instanceDetails)
    //         : instanceDetails.expectedDate;
    //     return (
    //         <div
    //             className="content textEllipsis"
    //             title={getChatTimestamp(dateToShow, "mm/dd/yyyy hh:mm aa")}
    //         >
    //             {isDocumentAvailable ? "Uploaded On: " : "Expected On: "}
    //             {getChatTimestamp(dateToShow, "mm/dd/yyyy hh:mm aa")}
    //         </div>
    //     );
    // };

    const getMenuItems = (instanceDetails, index, steps) => {
        if (instanceDetails.durationStatus === "PENDING") {
            if (instanceDetails.parentInstanceStepId !== "0")
                return [
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleClose}
                        id="upload"
                        key="upload"
                    >
                        <div
                            id="upload_from_system"
                            style={{ paddingLeft: "4px" }}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                id="upload"
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/upload.png"
                                alt="Upload"
                                title="Upload"
                                style={{ width: "10px", height: "21px" }}
                            />
                            <span
                                id="upload"
                                title="Upload"
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                            >
                                Upload
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleFormShow}
                        id="upload from workspace"
                        key="upload from workspace"
                    >
                        <div
                            id="upload from workspace"
                            style={{ paddingLeft: "4px" }}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/upload.png"
                                alt="Upload from workspace"
                                title="Upload from workspace"
                                style={{ width: "10px", height: "21px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Upload from workspace"
                            >
                                Upload from workspace
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleClose}
                        id="edit"
                        key="edit"
                    >
                        <div
                            id="edit"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/Rename.svg"
                                alt="Edit"
                                title="Edit"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Edit"
                            >
                                Edit
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleClose}
                        id="branch"
                        key="edit"
                    >
                        <div
                            id="branch"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/branch.svg"
                                alt="Branch"
                                title="Branch"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Branch"
                            >
                                Branch
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={deleteClickHandler}
                        id="edit"
                        key="edit"
                    >
                        <div
                            id="delete branch"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/trash 2.svg"
                                alt="Delete Branch"
                                title="Delete Branch"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Delete Branch"
                            >
                                Delete Branch
                            </span>
                        </div>
                    </MenuItem>,
                ];
            else
                return [
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleClose}
                        id="upload"
                        key="upload"
                    >
                        <div
                            id="upload"
                            style={{ paddingLeft: "4px" }}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                id="upload"
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/upload.png"
                                alt="Upload"
                                title="Upload"
                                style={{ width: "10px", height: "21px" }}
                            />
                            <span
                                id="upload"
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Upload"
                            >
                                Upload
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleFormShow}
                        id="upload from workspace"
                        key="upload from workspace"
                    >
                        <div
                            id="upload from workspace"
                            style={{ paddingLeft: "4px" }}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/upload.png"
                                alt="Upload from workspace"
                                title="Upload from workspace"
                                style={{ width: "10px", height: "21px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Upload from workspace"
                            >
                                Upload from workspace
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleClose}
                        id="edit"
                        key="edit"
                    >
                        <div
                            id="edit"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/Rename.svg"
                                alt="Edit"
                                title="Edit"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Edit"
                            >
                                Edit
                            </span>
                        </div>
                    </MenuItem>,
                ];
        } else if (
            instanceDetails.durationStatus === "DELAYED" ||
            instanceDetails.durationStatus === "CUSTOM" ||
            instanceDetails.durationStatus === "DATE CHANGED" ||
            instanceDetails.durationStatus === "DONE-RED"
        ) {
            if (instanceDetails.instanceBlockDocumentName)
                return [
                    <MenuItem onClick={handleClose} id="open">
                        <div id="open">
                            <img
                                className="deleteIcon"
                                src="./images/open.png"
                                alt="Open"
                                title="Open"
                            />
                            <span className="btn_title" title="Open">
                                Open
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleClose}
                        id="edit"
                        key="edit"
                    >
                        <div
                            id="edit"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/Rename.svg"
                                alt="Edit"
                                title="Edit"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Edit"
                            >
                                Edit
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleClose}
                        id="branch"
                        key="edit"
                    >
                        <div
                            id="branch"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/branch.svg"
                                alt="Branch"
                                title="Branch"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Branch"
                            >
                                Branch
                            </span>
                        </div>
                    </MenuItem>,
                ];
            else
                return [
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleClose}
                        id="upload"
                        key="upload"
                    >
                        <div
                            id="upload"
                            style={{ paddingLeft: "4px" }}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/upload.png"
                                alt="Upload"
                                title="Upload"
                                style={{ width: "10px", height: "21px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Upload"
                            >
                                Upload
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleFormShow}
                        id="upload from workspace"
                        key="upload from workspace"
                    >
                        <div
                            id="upload from workspace"
                            style={{ paddingLeft: "4px" }}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/upload.png"
                                alt="Upload from workspace"
                                title="Upload from workspace"
                                style={{ width: "10px", height: "21px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Upload from workspace"
                            >
                                Upload from workspace
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleClose}
                        id="edit"
                        key="edit"
                    >
                        <div
                            id="edit"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/Rename.svg"
                                alt="Edit"
                                title="Edit"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Edit"
                            >
                                Edit
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleClose}
                        id="branch"
                        key="edit"
                    >
                        <div
                            id="branch"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/branch.svg"
                                alt="Branch"
                                title="Branch"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Branch"
                            >
                                Branch
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={deleteClickHandler}
                        id="edit"
                        key="edit"
                    >
                        <div
                            id="delete branch"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/trash 2.svg"
                                alt="Delete Branch"
                                title="Delete Branch"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Delete Branch"
                            >
                                Delete Branch
                            </span>
                        </div>
                    </MenuItem>,
                ];
        } else if (
            instanceDetails.durationStatus === "DONE" ||
            instanceDetails.durationStatus === "DONE-GREEN"
        ) {
            if (instanceDetails.parentInstanceStepId === "0")
                return [
                    <MenuItem onClick={handleClose} id="open">
                        <div id="open">
                            <img
                                className="deleteIcon"
                                src="./images/open.png"
                                alt="Open"
                                title="Open"
                            />
                            <span className="btn_title" title="Open">
                                Open
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleClose}
                        id="edit"
                        key="edit"
                    >
                        <div
                            id="edit"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/Rename.svg"
                                alt="Edit"
                                title="Edit"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Edit"
                            >
                                Edit
                            </span>
                        </div>
                    </MenuItem>,
                ];
            else
                return [
                    <MenuItem onClick={handleClose} id="open">
                        <div id="open">
                            <img
                                className="deleteIcon"
                                src="./images/open.png"
                                alt="Open"
                                title="Open"
                            />
                            <span className="btn_title" title="Open">
                                Open
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleClose}
                        id="edit"
                        key="edit"
                    >
                        <div
                            id="edit"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/Rename.svg"
                                alt="Edit"
                                title="Edit"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Edit"
                            >
                                Edit
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        disabled={isReadOnlyUser}
                        onClick={handleClose}
                        id="branch"
                        key="edit"
                    >
                        <div
                            id="branch"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/branch.svg"
                                alt="Branch"
                                title="Branch"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Branch"
                            >
                                Branch
                            </span>
                        </div>
                    </MenuItem>,
                ];
        } else if (instanceDetails.durationStatus === "SKIPPED")
            return [
                <MenuItem
                    disabled={isReadOnlyUser}
                    onClick={handleClose}
                    id="upload"
                    key="upload"
                >
                    <div
                        id="upload_from_system"
                        style={{ paddingLeft: "4px" }}
                        className={isReadOnlyUser ? "readOnly" : ""}
                    >
                        <img
                            id="upload"
                            className={
                                isReadOnlyUser
                                    ? "renameIcon readOnly"
                                    : "renameIcon"
                            }
                            src="./images/upload.png"
                            alt="Upload"
                            title="Upload"
                            style={{ width: "10px", height: "21px" }}
                        />
                        <span
                            id="upload"
                            title="Upload"
                            className={
                                isReadOnlyUser
                                    ? "btn_title readOnly"
                                    : "btn_title"
                            }
                        >
                            Upload
                        </span>
                    </div>
                </MenuItem>,
                <MenuItem
                    disabled={isReadOnlyUser}
                    onClick={handleFormShow}
                    id="upload from workspace"
                    key="upload from workspace"
                >
                    <div
                        id="upload from workspace"
                        style={{ paddingLeft: "4px" }}
                        className={isReadOnlyUser ? "readOnly" : ""}
                    >
                        <img
                            className={
                                isReadOnlyUser
                                    ? "renameIcon readOnly"
                                    : "renameIcon"
                            }
                            src="./images/upload.png"
                            alt="Upload from workspace"
                            title="Upload from workspace"
                            style={{ width: "10px", height: "21px" }}
                        />
                        <span
                            className={
                                isReadOnlyUser
                                    ? "btn_title readOnly"
                                    : "btn_title"
                            }
                            title="Upload from workspace"
                        >
                            Upload from workspace
                        </span>
                    </div>
                </MenuItem>,
                parseInt(instanceDetails.parentInstanceStepId) !== 0 &&
                    instanceDetails.blockType !== "Optional" && (
                        <MenuItem
                            disabled={isReadOnlyUser}
                            onClick={handleClose}
                            id="branch"
                            key="edit"
                        >
                            <div
                                id="branch"
                                className={isReadOnlyUser ? "readOnly" : ""}
                            >
                                <img
                                    className={
                                        isReadOnlyUser
                                            ? "renameIcon readOnly"
                                            : "renameIcon"
                                    }
                                    src="./images/branch.svg"
                                    alt="Branch"
                                    title="Branch"
                                    style={{ width: "18px", height: "18px" }}
                                />
                                <span
                                    className={
                                        isReadOnlyUser
                                            ? "btn_title readOnly"
                                            : "btn_title"
                                    }
                                    title="Branch"
                                >
                                    Branch
                                </span>
                            </div>
                        </MenuItem>
                    ),
            ];
    };
    const showModalView = (fileName, documentId) => {
        setShowLoader(true);
        http.sendRestRequest(`document/documentDetail?documentId=${documentId}`)
            .then((response) => {
                setShowLoader(false);
                if (response.documentStream) {
                    setCurrentShowFileName(fileName);
                    setDocumentModalShow(true);
                    const fileType = response.documentName.split(".").pop();
                    if (fileType === "pdf") {
                        setTimeout(() => {
                            setCurrentShowFileLocation(response);
                        }, 1);
                    } else {
                        setCurrentShowFileLocation(response);
                    }
                    // dispatch(
                    //     showAlert({
                    //         currentShowFileName: fileName,
                    //         currentShowFileLocation: response,
                    //         documentModalShow: true,
                    //     })
                    // );
                } else {
                    setShowAlertDialog(true);
                    setAlertDialogMessage("The file is not available");
                }
            })
            .catch((error) => {
                setShowAlertDialog(true);
                setShowLoader(false);
                setAlertDialogMessage(error.error);
                console.log(error);
            });
    };
    const changeOpenDialogFlag = (value) => {
        setShowAlertDialog(value);
        dispatch(setUpdatedInstanceBranch(true));
        setAlertDialogMessage("");
    };
    const closeEditModal = () => {
        setShowEditStepModal(false);
        setRawDataToEdit(null);
        setBranchShow(false);
    };
    const isDataChanged = (newData) => {
        let newDataKeys = Object.keys(newData);
        let editedKeys = [];
        newDataKeys.forEach((key) => {
            if (
                rawDataToEdit[key] !== undefined &&
                rawDataToEdit[key] !== newData[key]
            ) {
                editedKeys.push(key);
            }
        });
        return editedKeys;
    };
    const editExistingStep = (data) => {
        setShowEditStepModal(false);
        let changedDataKeys = isDataChanged(data);
        let payload = {};
        if (changedDataKeys.length) {
            changedDataKeys.forEach((key) => {
                if (key === "fromEntity") {
                    payload["from"] = data[key];
                } else if (key === "toEntity") {
                    payload["to"] = data[key];
                } else if (key === "genericStepName") {
                    payload["instanceStepName"] = data[key];
                } else {
                    payload[key] = data[key];
                }
            });
            // payload.genericStepId = instanceDetails.genericStepId;
            // payload.stepName = instanceDetails.instanceStepName;
            // payload.parentInstanceStepId = instanceDetails.parentInstanceStepId;
            payload.instanceStepId = instanceDetails.instanceStepId;
            // payload.instanceId = instanceDetails.instanceId;
            editInstanceStep(payload);
        }
        setRawDataToEdit(null);
    };
    const handleDocumentModalClose = () => {
        setDocumentModalShow(false);
        setCurrentShowFileLocation("");
    };

    const editInstanceStep = (value) => {
        setShowLoader(true);
        http.sendRestRequest(
            `documentChain/instance/step?genericId=${genericId}`,
            "PUT",
            value
        )
            .then(() => {
                http.sendRestRequest(
                    `documentChain/instanceData?genericId=${genericId}&instanceId=${data.instanceId}`
                )
                    .then((response) => {
                        const genericSteps = [...selectedGenericSteps].sort(
                            (a, b) => {
                                return (
                                    parseInt(a.genericOrderId) -
                                    parseInt(b.genericOrderId)
                                );
                            }
                        );
                        const instanceSteps = getInstanceSteps(
                            response,
                            genericSteps
                        );
                        const mergeSteps = instanceSteps.filter(
                            (step) => step.blockType === "MERGE"
                        );
                        const executedSteps = instanceSteps
                            .filter((step) => step.actualDate)
                            .sort((a, b) => {
                                const date1 = new Date(a.actualDate);
                                const date2 = new Date(b.actualDate);
                                return date1 - date2;
                            });
                        const nonExecutedStepsWithExpectedDate = instanceSteps
                            .filter(
                                (step) =>
                                    step.expectedDate &&
                                    step.instanceBlockDocumentName === "" &&
                                    step.durationStatus !== "SKIPPED"
                            )
                            .sort((a, b) => {
                                return (
                                    parseInt(a.expectedDate) -
                                    parseInt(b.expectedDate)
                                );
                            });
                        const skippedSteps = instanceSteps
                            .filter((step) => step.durationStatus === "SKIPPED")
                            .sort(
                                (a, b) => b.instanceStepId - a.instanceStepId
                            );
                        skippedSteps.forEach((step) => {
                            if (step.parentInstanceStepId === "0") {
                                executedSteps.splice(0, 0, step);
                            } else {
                                const prevExcSteps = executedSteps.filter(
                                    (executedStep) =>
                                        executedStep.instanceViewOrder >
                                        step.instanceViewOrder
                                );
                                const prevStep = prevExcSteps.length
                                    ? prevExcSteps[0]
                                    : null;
                                if (prevStep) {
                                    let index = executedSteps.findIndex(
                                        (executedStep) =>
                                            executedStep.instanceStepName ===
                                            prevStep.instanceStepName
                                    );
                                    executedSteps.splice(index, 0, step);
                                } else executedSteps.push(step);
                            }
                        });
                        const nonExecutedSteps = instanceSteps.filter(
                            (step) =>
                                step.actualDate === "" &&
                                step.expectedDate === "" &&
                                step.durationStatus !== "SKIPPED"
                        );
                        let sortedNonExecutedSteps = [];
                        genericSteps.forEach((genStep) => {
                            let instanceSteps = nonExecutedSteps.filter(
                                (instanceStep) =>
                                    genStep.genericStepName ===
                                    instanceStep.instanceStepName
                            );
                            // .sor((a, b) => a.branchId - b.branchId);
                            sortedNonExecutedSteps = [
                                ...sortedNonExecutedSteps,
                                ...instanceSteps,
                            ];
                        });
                        const allNonExecutedSteps = [
                            ...nonExecutedStepsWithExpectedDate,
                            ...sortedNonExecutedSteps,
                        ];
                        const genStepNames = genericSteps.map(
                            (step) => step.genericStepName
                        );
                        const customNonExecutedSteps = nonExecutedSteps.filter(
                            (step) =>
                                !genStepNames.includes(step.instanceStepName) &&
                                step.instanceUniqueStepIdentifier !== "SPLIT" &&
                                step.instanceUniqueStepIdentifier !== "MERGE"
                        );
                        customNonExecutedSteps.forEach((step) => {
                            const instanceStepIds = allNonExecutedSteps.map(
                                (ns) => ns.instanceStepId
                            );
                            const index = instanceStepIds.indexOf(
                                parseInt(step.parentInstanceStepId)
                            );
                            allNonExecutedSteps.splice(index + 1, 0, step);
                        });
                        setSelectedInstanceSteps([
                            ...executedSteps,
                            ...allNonExecutedSteps,
                        ]);
                        setSelectedMergeBlocks(mergeSteps);
                        const promiseBlockNames = genericSteps.map(
                            (step) => step.genericStepName
                        );
                        const promiseBlocks = instanceSteps.filter((step) =>
                            promiseBlockNames.includes(step.instanceStepName)
                        );
                        setBranchViewData({
                            selectedInstanceSteps: [...promiseBlocks],
                            selectedMergeBlocks: mergeSteps,
                        });
                        setShowAlertDialog(true);

                        setSnackbarMessage(
                            "Instance step updated successfully!"
                        );
                        setSelectedMergeBlocks(mergeSteps);
                        setShowSnackbar(true);
                        setShowLoader(false);
                        document
                            .getElementById("div_instanceRefresh_icon")
                            .click();
                    })
                    .catch((error) => {
                        setShowLoader(false);
                        console.log(error);
                        setShowAlertDialog(true);

                        setAlertDialogMessage(error.error);
                    });
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);

                setAlertDialogMessage(error.error);
            });
    };
    const renderViewDocumentModal = (event) => {
        return (
            <DocViwer
                docName={currentShowFileName}
                fileLocation={currentShowFileLocation}
            ></DocViwer>
        );
    };
    const onChangeFileUpload = (
        event,
        instanceDetails,
        genericId,
        previousStep
    ) => {
        let formFileData = event.target.files;
        let fileSize = formFileData[0].size;
        let maxSizeLimit = window.runtimeConfig.maxFileSize; //in mb
        let formFile = Array.from(formFileData);

        let fileName = formFile[0].name;
        let isValidFileType = validateFileType(fileName);
        if (!isValidFileType) {
            setShowAlertDialog(true);
            setAlertDialogMessage("File Type Is Not Supported.");
            return;
        }

        let fileSizeInMB = fileSize / 1024 / 1024;

        if (fileSizeInMB > maxSizeLimit) {
            setShowAlertDialog(true);
            setAlertDialogMessage(
                "File size should be maximum " + maxSizeLimit + " MB"
            );

            return;
        }
        if (!instanceDetails.parentInstanceStepId) {
            setShowAlertDialog(true);
            setAlertDialogMessage(
                "Not allowed to upload the document, Step does not have parent link"
            );
            return;
        }
        if (formFileData.length > 0) {
            const data = new FormData();
            data.append(
                "file",
                formFileData[0],
                encodeURIComponent(formFileData[0].name)
            );
            setShowLoader(true);
            http.uploadFile(
                data,
                `rest/documentChain/instance/step/updateBlock?genericId=${genericId}&instanceStepId=${instanceDetails.instanceStepId}`,
                "PUT"
            )
                .then((success) => {
                    let splitFileName = formFileData[0].name.split(".");
                    let fileExt = splitFileName.pop();
                    let fileSize = fileSizeInMB + "MB";
                    Mixpanel.callFileManagementEvent(
                        "Upload",
                        fileExt,
                        fileSize
                    );
                    dispatch(
                        setSnackbarAction({
                            open: "true",
                            message: "Document Added Successfully!",
                            severity: "success",
                        })
                    );
                    setShowLoader(false);
                    document.getElementById("div_instanceRefresh_icon").click();
                    // fetchInstanceList(selectedInstance);
                })
                .catch((error) => {
                    // showAlertView(
                    //     "Operation Failed",
                    //     error.error,
                    //     "",
                    //     "",
                    //     true
                    // );
                    setShowLoader(false);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                });
        }
    };
    const handleClose = (e) => {
        setAnchorEl(null);
        if (
            e.target &&
            (e.target.id === "open" || e.target.parentNode.id === "open")
        ) {
            showModalView(
                instanceDetails.instanceBlockDocumentName,
                instanceDetails.instanceBlockDocumentId
            );
        } else if (
            e.target &&
            (e.target.id === "edit" || e.target.parentNode.id === "edit")
        ) {
            setShowEditStepModal(true);
            setRawDataToEdit({
                genericStepUniqueIdentifier:
                    instanceDetails.instanceUniqueStepIdentifier,
                toEntity: instanceDetails.to,
                fromEntity: instanceDetails.from,
                genericStepName: instanceDetails.instanceStepName,
                expectedDuration: instanceDetails.expectedDuration,
                expectedDurationFrom: instanceDetails.expectedDurationFrom,
                documentName: instanceDetails.instanceBlockDocumentName,
                workspaceId: instanceDetails.workspaceId,
                spaceId: instanceDetails.spaceId,
                folderId: instanceDetails.folderId,
                parentInstanceStepId: instanceDetails.parentInstanceStepId,
                expectedDate: instanceDetails.expectedDate,
                branchId: instanceDetails.branchId,
            });
        } else if (
            e.target &&
            (e.target.id === "upload" || e.target.parentNode.id === "upload")
        ) {
            // openDocumentSubMenu(e);
            tableFileUplode.current.click();
        } else if (
            e.target &&
            (e.target.id === "branch" || e.target.parentNode.id === "branch")
        ) {
            setBranchShow(true);
        }
    };

    return (
        <>
            {instanceDetails.durationStatus === "PENDING" ||
            instanceDetails.durationStatus === undefined ||
            instanceDetails.durationStatus === "" ? (
                <div
                    className={"pendingStepContainer"}
                    style={
                        instanceDetails.genericStepId
                            ? {
                                  background: "#eef9ff",
                                  border: "3px solid #009fff",
                                  borderRadius: "10px",
                              }
                            : {
                                  border: "3px dashed #000000",
                                  borderRadius: "10px",
                              }
                    }
                >
                    <div className="contentRow">
                        <div className="content textEllipsis">{`Name: ${
                            instanceDetails.instanceStepName ||
                            instanceDetails.genericStepName
                        }`}</div>
                        {instanceDetails.instanceStepId ? (
                            <div
                                onClick={(event) =>
                                    instanceDetails.instanceStepId
                                        ? handleClick(event)
                                        : () => {}
                                }
                                style={{
                                    // cursor: isReadOnlyUser
                                    //     ? "not-allowed"
                                    //     : "pointer",
                                    cursor: "pointer",
                                    height: "24px",
                                }}
                            >
                                <img
                                    alt={"options"}
                                    title="options"
                                    src="./images/options.png"
                                ></img>
                            </div>
                        ) : null}
                    </div>
                    <div className="contentRow">
                        <div className="content textEllipsis">
                            {instanceDetails.branchName
                                ? `Branch Name: ${instanceDetails.branchName}`
                                : ""}
                        </div>
                    </div>
                    {data.genericStepId ? (
                        <>
                            <div className="contentRow">
                                <div
                                    className="content textEllipsis"
                                    // style={{
                                    //     cursor: "pointer",
                                    // }}
                                    title={data.promiseType}
                                >
                                    Promise Type: {data.promiseType}
                                </div>

                                {data.blockType === "Optional" ? (
                                    <div className="content contentChip textEllipsis">
                                        <Chip
                                            label={"OPTIONAL"}
                                            style={{
                                                color: "black",
                                                background: "white",
                                                border: "1px solid black",
                                                width: "100px",
                                                height: "30px",
                                            }}
                                            size="medium"
                                        ></Chip>
                                    </div>
                                ) : null}
                            </div>
                        </>
                    ) : null}
                    <div className="contentRow">
                        <div
                            className="contentRow"
                            title={
                                instanceDetails.instanceStepId
                                    ? renderExpectedTimeRow(instanceDetails)
                                    : ""
                            }
                        >
                            {instanceDetails.instanceStepId
                                ? renderExpectedTimeRow(instanceDetails)
                                : ""}
                        </div>
                        {instanceDetails.blockType === "Optional" &&
                        instanceDetails.instanceStepId ? (
                            <div className="content contentChip textEllipsis">
                                <Chip
                                    label={"OPTIONAL"}
                                    style={{
                                        color: "black",
                                        background: "white",
                                        border: "1px solid black",
                                        width: "100px",
                                        height: "30px",
                                        marginBottom: "10px",
                                    }}
                                    size="medium"
                                ></Chip>
                            </div>
                        ) : null}
                    </div>
                    <div className="contentRow">
                        <div
                            className="content textEllipsis"
                            title={
                                instanceDetails.from ||
                                instanceDetails.fromEntity
                            }
                        >
                            From:
                            {instanceDetails.from || instanceDetails.fromEntity}
                        </div>
                        <div
                            className="content textEllipsis"
                            title={
                                instanceDetails.to || instanceDetails.toEntity
                            }
                        >
                            To:
                            {instanceDetails.to || instanceDetails.toEntity}
                        </div>

                        <div className="content contentChip textEllipsis">
                            <Chip
                                label={"PENDING"}
                                style={{
                                    width: "100px",
                                    height: "30px",
                                }}
                                size="medium"
                            ></Chip>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        border:
                            instanceDetails.durationStatus === "DONE-GREEN" ||
                            instanceDetails.durationStatus === "DONE"
                                ? "3px solid #02b734"
                                : instanceDetails.durationStatus === "DONE-RED"
                                ? "3px solid red"
                                : instanceDetails.durationStatus === "SKIPPED"
                                ? "3px solid yellow"
                                : "3px solid #cc0000",
                        backgroundColor:
                            instanceDetails.durationStatus === "DONE-GREEN" ||
                            instanceDetails.durationStatus === "DONE"
                                ? "#f4fbff"
                                : instanceDetails.durationStatus === "DONE-RED"
                                ? "rgba(252, 0, 0, 0.04)"
                                : instanceDetails.durationStatus === "SKIPPED"
                                ? "rgba(252, 252, 0, 0.04)"
                                : "rgba(252, 0, 0, 0.04)",
                    }}
                    className={
                        instanceDetails.durationStatus === "DELAYED" ||
                        instanceDetails.durationStatus === "CUSTOM" ||
                        instanceDetails.durationStatus === "DONE-RED"
                            ? "delayedStepContainer"
                            : "doneStepContainer"
                    }
                >
                    <div className="contentRow">
                        <div
                            className="content textEllipsis"
                            title={instanceDetails.instanceStepName}
                        >{`Name: ${instanceDetails.instanceStepName}`}</div>

                        <div
                            onClick={(event) =>
                                instanceDetails.instanceStepId
                                    ? handleClick(event)
                                    : () => {}
                            }
                            style={{
                                // cursor: isReadOnlyUser
                                //     ? "not-allowed"
                                //     : "pointer",
                                cursor: "pointer",
                                height: "24px",
                            }}
                        >
                            <img
                                alt={"options"}
                                title="options"
                                src="./images/options.png"
                            ></img>
                        </div>
                    </div>

                    <div className="contentRow">
                        <div
                            className="content textEllipsis"
                            style={{ width: "330px" }}
                            title={instanceDetails.branchName}
                        >
                            {`Branch Name: ${instanceDetails.branchName}`}
                        </div>
                    </div>
                    <div className="contentRow">
                        <div
                            className="content textEllipsis"
                            style={{ width: "330px" }}
                            title={instanceDetails.instanceBlockDocumentName}
                        >
                            File Name:{" "}
                            {instanceDetails.instanceBlockDocumentName}
                        </div>
                    </div>
                    {/* <div className="contentRow">
                        <div className="content">
                            Location: {instanceDetails.location}
                        </div>
                    </div> */}
                    <div
                        className="contentRow"
                        style={{ justifyContent: "space-between" }}
                    >
                        <div
                            className="contentRow"
                            style={{ justifyContent: "space-between" }}
                        >
                            <div
                                className="content textEllipsis"
                                title={instanceDetails.from}
                            >
                                From: {instanceDetails.from}
                            </div>
                            <div
                                className="content textEllipsis"
                                style={{ paddingLeft: "12px" }}
                                title={instanceDetails.to}
                            >
                                To: {instanceDetails.to}
                            </div>
                        </div>
                        {instanceDetails.blockType === "Optional" &&
                        instanceDetails.instanceStepId ? (
                            <div className="content contentChip textEllipsis">
                                <Chip
                                    label={"OPTIONAL"}
                                    style={{
                                        color: "black",
                                        background: "white",
                                        border: "1px solid black",
                                        width: "100px",
                                        height: "30px",
                                        marginBottom: "10px",
                                    }}
                                    size="medium"
                                ></Chip>
                            </div>
                        ) : null}
                    </div>
                    <div className="contentRow">
                        {instanceDetails.instanceStepId
                            ? renderExpectedTimeRow(instanceDetails)
                            : ""}
                        <div className="content contentChip textEllipsis">
                            <Chip
                                title={
                                    instanceDetails.durationStatus ===
                                        "DONE-GREEN" ||
                                    instanceDetails.durationStatus ===
                                        "DONE-RED"
                                        ? "DONE"
                                        : instanceDetails.durationStatus
                                }
                                label={
                                    instanceDetails.durationStatus ===
                                        "DONE-GREEN" ||
                                    instanceDetails.durationStatus ===
                                        "DONE-RED"
                                        ? "DONE"
                                        : instanceDetails.durationStatus
                                }
                                // color={
                                //     instanceDetails.durationStatus === "DONE"
                                //         ? "success"
                                //         : "secondary"
                                // }
                                style={{
                                    width:
                                        instanceDetails.durationStatus ===
                                            "KEY MISMATCH" ||
                                        instanceDetails.durationStatus ===
                                            "KEY MISSING"
                                            ? "130px"
                                            : "100px",
                                    height: "30px",
                                    background:
                                        instanceDetails.durationStatus ===
                                            "DONE" ||
                                        instanceDetails.durationStatus ===
                                            "DONE-GREEN"
                                            ? "#02b734"
                                            : instanceDetails.durationStatus ===
                                              "SKIPPED"
                                            ? " yellow"
                                            : " #cc0000",
                                    color:
                                        instanceDetails.durationStatus ===
                                            "DONE" ||
                                        instanceDetails.durationStatus ===
                                            "DONE-GREEN"
                                            ? "white"
                                            : instanceDetails.durationStatus ===
                                              "SKIPPED"
                                            ? " black"
                                            : " white",
                                }}
                                size="medium"
                            ></Chip>
                        </div>
                    </div>
                </div>
            )}
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {getMenuItems(instanceDetails)}
            </Menu>
            <input
                type="file"
                id="file"
                ref={tableFileUplode}
                hidden={true}
                onChange={(event) =>
                    onChangeFileUpload(
                        event,
                        instanceDetails,
                        genericId
                        // previousStep
                    )
                }
                onClick={(event) => {
                    event.currentTarget.value = null;
                }}
                multiple
            />
            <DocumentViewerModal
                showHeader={true}
                footer="false"
                show={documentModalShow}
                documentId={currentShowFileLocation.documentId}
                size="lg"
                onHide={handleDocumentModalClose}
                title=""
                modalBodyClass="documentViewModal"
                onclick={() => {}}
                submitButtonText="Close"
                headerTitle={currentShowFileName}
                fileSource={currentShowFileLocation}
            >
                {documentModalShow ? renderViewDocumentModal() : null}
            </DocumentViewerModal>
            <AddBranch
                showBranch={branchShow}
                setBranchShow={setBranchShow}
                closeModal={closeEditModal}
                title={"Branch"}
                btnName={"Delete"}
                steps={instanceSteps}
                addBranch={addBranch}
                instanceDetails={instanceDetails}
                instanceSteps={instanceSteps}
            ></AddBranch>
            <Loader show={showLoader}></Loader>

            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
                setOpen={changeOpenDialogFlag}
            ></AlertDialog>
            {rawDataToEdit && rawDataToEdit.documentName ? (
                <AddStep
                    showAddDocumentChainModal={showEditStepModal}
                    closeModal={closeEditModal}
                    addNewStep={editExistingStep}
                    title={"Edit Step"}
                    btnName={"Update"}
                    genericStepList={[]}
                    editStepData={rawDataToEdit}
                    isInstanceStep={true}
                ></AddStep>
            ) : (
                <DialogView
                    show={showEditStepModal}
                    size="lg"
                    handleClose={closeEditModal}
                    showTitle={false}
                    showFooter={false}
                    onBackdropClick={false}
                    style={
                        displayShow ? { display: "none" } : { display: "block" }
                    }
                    scrollId={"GenericOrInstanceStepId"}
                    className={displayShow ? "" : "invisibleScroller"}
                >
                    <AddStepStepper
                        Display={setDisplayShow}
                        onHide={closeEditModal}
                        hideFile={"true"}
                        saveButtonName={"NEXT"}
                        isInstanceStep={true}
                        genericStepList={
                            instanceSteps &&
                            instanceSteps.map((step) => {
                                return {
                                    ...step,
                                    genericStepUniqueIdentifier:
                                        step.instanceUniqueStepIdentifier,
                                    genericStepName: step.instanceStepName,
                                };
                            })
                        }
                        title={"Edit Step"}
                        curruntAction={"edit"}
                        editStepData={rawDataToEdit}
                        addNewStep={editExistingStep}
                        nameBranch={instanceDetails.branchName}
                        parentStepId={
                            rawDataToEdit &&
                            parseInt(rawDataToEdit.parentInstanceStepId)
                        }
                    ></AddStepStepper>
                </DialogView>
            )}
            <DialogView
                show={showStepperForm}
                size="lg"
                handleClose={handleCloseStepperForm}
                showTitle={false}
                showFooter={false}
                onBackdropClick={false}
            >
                <FormWrapper
                    selectedInstance={selectedInstance}
                    fetchInstanceList={(selectedInstance) => {
                        console.log(selectedInstance);
                    }}
                    onHide={handleCloseUploadDialog}
                    accountType={"headerTitle"}
                    defaultStep={1}
                    alertView={showAlertView}
                    Display={setDisplayShow}
                    instanceDetails={instanceDetails}
                    genericId={genericId}
                    heading={"Upload File"}
                ></FormWrapper>
            </DialogView>
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
                severity={
                    snackbarMessage === "Instance name already exists"
                        ? "error"
                        : "success"
                }
            ></CustomSnackbar>
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                handleConfirmationDialog={handleConfirmationDialog}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
                messageId={alertDialogContent.messageId}
                buttonId={alertDialogContent.buttonId}
                buttonName={alertDialogContent.buttonName}
            ></AlertDialog>
        </>
    );
};

export default Step;
